


































import { recommendAccurate } from '@/api/costQuotaLibrary/costLibrary';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
import _ from 'lodash';
import Vue from 'vue';
import combinationTable from '../../components/combinationTable.vue';

const columns = [
  {
    title: '项目编码',
    key: 'bdCode',
  },
  {
    title: '项目名称',
    key: 'bdName',
  },
  {
    title: '单位',
    key: 'unit',
  },
  {
    title: '综合单价(元)',
    key: 'price',
  },
];
export default Vue.extend({
  name: 'recommend',
  components: { combinationTable },
  props: {
    actionRecord: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    rowSelection() {
      return {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
          // this.$emit('rowSelectChange', { selectedRowKeys, selectedRows });
        },
      };
    },
  },
  data() {
    return {
      selectedRows: [],
      tableData: [] as QyStlBillDeType[],
      columns,
      actionList: [],
    };
  },
  mounted() {
    this.getRecommendData();
  },
  methods: {
    getRecommendData() {
      if (!this.actionRecord) return;
      const { bdCode, projectAttr, unit, bdName } = this.actionRecord;
      recommendAccurate({
        fxCode: bdCode,
        fxName: bdName,
        projectAttr,
        unit,
        limit: 5,
        orderField: 'price',
      }).then(({ status, result }) => {
        if (status === 200 && result) {
          this.tableData = result;
          this.$emit('recommendData', this.tableData);
        }
        console.log('recommend.vue|75====>', 'result', result);
      });
    },
    useHandler() {
      if (this.selectedRows.length === 0)
        return this.$message.error('请先选择定额');
      let selectedList = [] as any[];
      this.selectedRows.forEach((item: any) => {
        const quotaData = item.deList || [];
        selectedList = [...selectedList, ...quotaData];
      });
      if (!selectedList.length) return;
      this.$emit('useSelectedData', _.cloneDeep(selectedList));
    },
  },
});
