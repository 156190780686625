var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recommend-combination scrollbar"},[_c('combinationTable',{staticClass:"recommend-table",attrs:{"detailedListBind":{
      tableData: _vm.tableData,
      columns: _vm.columns,
      actionList: _vm.actionList,
      customAction: true,
      showPagination: false,
      rowSelection: _vm.rowSelection,
      fieldSlots: Object.freeze(['bdCode']),
    },"quotaBind":{ actionList: _vm.actionList, editFields: [] }}}),_c('div',{staticClass:"btn-group"},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.selectedRows.length},on:{"click":_vm.useHandler}},[_vm._v(" 一键智能应用 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }